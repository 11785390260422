import { css } from 'styled-components';

export const MEDIA_SIZES = {
  desktop: 1220,
  desktopSmall: 992,
  tablet: 768,
  mobileWide: 576,
};

/**
 * @property {function} desktop
 */
const media = Object.keys(MEDIA_SIZES).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${MEDIA_SIZES[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

export default media;
