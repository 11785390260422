import { commitMutation } from 'react-relay';

const commitMutationPromise = (environment, config) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      ...config,
      onCompleted: (payload, errors) => {
        if (errors) return reject({ graphQLErrors: errors });
        return resolve(payload);
      },
      onError: error => {
        return reject({ networkError: error });
      }
    });
  });
};

export default commitMutationPromise;
