import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import commonMessages from 'commonMessages';
import LoginMutation from 'mutations/LoginMutation';
import environment from 'environment';
import { isAuthenticated } from 'utils/authenticationUtil';
import CenterBase from 'components/Center';
import TextFieldBase from 'components/TextField';
import Button from 'components/Button';
import StucapIcon from 'components/Icons/StucapIcon';

const messages = defineMessages({
  email: {
    id: 'LoginScreen.email.label',
    defaultMessage: 'E-mail'
  },
  password: {
    id: 'LoginScreen.password.label',
    defaultMessage: 'Password'
  },
  loginButton: {
    id: 'LoginScreen.loginButton',
    defaultMessage: 'Login'
  },
  title: {
    id: 'LoginScreen.title',
    defaultMessage: 'Welcome'
  },
  subtitle: {
    id: 'LoginScreen.subtitle',
    defaultMessage: 'log in to continue'
  }
});

const LoginSchema = Yup.object({
  email: Yup.string().required(
    <FormattedMessage {...commonMessages.required} />
  ),
  password: Yup.string().required(
    <FormattedMessage {...commonMessages.required} />
  )
});

const Container = styled.div`
  padding: 0 40px;
  margin-bottom: 50px;
`;

const Center = styled(CenterBase)`
  && {
    max-width: 400px;
  }
`;

const IconHolder = styled.div`
  margin-top: 50px;
  color: ${props => props.theme.palette.primary.main};
`;

const Title = styled.p`
  margin: 0;
  margin-top: 35px;

  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 36px;
  font-weight: 500;
  color: ${props => props.theme.palette.text.primary};
`;

const Subtitle = styled.p`
  margin: 0;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 24px;
  font-weight: 400;
  color: rgba(29, 29, 29, 0.25);
`;

const FormHolder = styled.div`
  margin-top: 100px;
`;

const TextField = styled(TextFieldBase)`
  margin-top: 12px;
`;

const LoginButton = styled(Button)`
  margin-top: 45px;

  box-shadow: none;
  text-transform: none;
`;

const ErrorMessage = styled.p`
  margin-top: 20px;

  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.palette.error.main};
`;

const LoginScreen = ({ location }) => {
  const [submissionError, setSubmissionError] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(null);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      await LoginMutation.commit(environment, values);
      setRedirectToReferrer(true);
    } catch ({ networkError, graphQLErrors }) {
      let errorMessage = <FormattedMessage {...commonMessages.genericError} />;
      if (graphQLErrors && graphQLErrors.length) {
        errorMessage = graphQLErrors[0].message;
      }
      setSubmissionError(errorMessage);
      setSubmitting(false);
    }
  };

  const { from } = location.state || { from: { pathname: '/' } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  if (isAuthenticated()) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      <Center>
        <IconHolder>
          <StucapIcon />
        </IconHolder>
        <Title>
          <FormattedMessage {...messages.title} />,
        </Title>
        <Subtitle>
          <FormattedMessage {...messages.subtitle} />
        </Subtitle>
        <Formik
          initialValues={{ email: '', password: '' }}
          validationSchema={LoginSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid }) => (
            <FormHolder>
              <Form>
                <Field
                  component={TextField}
                  label={<FormattedMessage {...messages.email} />}
                  name="email"
                  type="text"
                  autoComplete="email"
                  fullWidth
                  autoFocus
                />
                <Field
                  component={TextField}
                  label={<FormattedMessage {...messages.password} />}
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  fullWidth
                />
                {submissionError && (
                  <ErrorMessage>{submissionError}</ErrorMessage>
                )}
                <LoginButton
                  type="submit"
                  size="large"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!isValid || isSubmitting}
                >
                  <FormattedMessage {...messages.loginButton} />
                </LoginButton>
              </Form>
            </FormHolder>
          )}
        </Formik>
      </Center>
    </Container>
  );
};

export default LoginScreen;
