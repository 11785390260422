import React from 'react';
import MuiButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const Button = ({ loading, children, ...rest }) => (
  <MuiButton {...rest}>
    {loading ? <CircularProgress size={16} /> : children}
  </MuiButton>
);

export default Button;
