import React from 'react';

const StucapIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="32"
    viewBox="0 0 37 25"
    {...props}
  >
    <g fill="currentColor" fill-rule="evenodd">
      <path d="M32.84 24.43c.13.14.31.21.5.21h1.7c.37 0 .7-.34.7-.72l.01-1.6a.45.45 0 0 0 .43-.44l.01-3.38a.44.44 0 0 0-.44-.44l-3.09.01a.44.44 0 0 0-.44.45l-.01 3.37a.44.44 0 0 0 .43.44v1.6c-.01.19.06.37.2.5zm.33-.5v-1.6h2.04v1.59c0 .1-.09.17-.18.17h-1.69a.17.17 0 0 1-.17-.16z" />
      <path d="M34.97 23.13a.3.3 0 0 0-.3-.3.3.3 0 0 0-.3.3c0 .17.13.3.3.3a.3.3 0 0 0 .3-.3zM34.1 23.14a.3.3 0 0 0-.3-.3.3.3 0 0 0-.31.3c0 .17.13.3.3.3a.3.3 0 0 0 .3-.3z" />
      <g>
        <path d="M33.47 8.1h1.36v10.54h-1.36z" />
      </g>
      <g>
        <path d="M18.51 15.94L6.1 11.49l.04 8.15s5.81 2.93 12.38 2.93c6.87 0 12.43-3.46 12.43-3.46l-.05-7.61-12.38 4.44z" />
        <path d="M37.03 7.52L18.5.04 0 7.52l18.51 6.78 14.8-5.41 1.4-.51 2.32-.86z" />
      </g>
    </g>
  </svg>
);

export default StucapIcon;
