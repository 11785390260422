import {
  Environment,
  RecordSource,
  Store,
} from 'relay-runtime';
import network from './network';

const source = new RecordSource();
const store = new Store(source);

const environment = new Environment({
  network,
  store
});

export default environment;
