import graphql from 'babel-plugin-relay/macro';
import commitMutation from 'utils/commitMutationPromise';
import { persistToken } from 'utils/authenticationUtil';

const mutation = graphql`
  mutation LoginMutation($input: LoginInput!) {
    login(input: $input) {
      auth {
        token
      }
    }
  }
`;

const commit = async (environment, variables) => {
  const response = await commitMutation(environment, {
    mutation,
    variables: {
      input: variables
    }
  });
  const { token } = response.login.auth;
  persistToken(token);
  return response;
};

export default { commit };
