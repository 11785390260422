import { LS_AUTH_TOKEN } from 'consts';

export const isAuthenticated = () => !!localStorage.getItem(LS_AUTH_TOKEN);

export const persistToken = token => {
  if (!token) {
    return localStorage.removeItem(LS_AUTH_TOKEN);
  }
  localStorage.setItem(LS_AUTH_TOKEN, token);
};

export const retrieveToken = () => localStorage.getItem(LS_AUTH_TOKEN);
