import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MuiThemeProvider,
  createGenerateClassName,
  jssPreset
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { IntlProvider } from 'react-intl';

import theme from 'muiTheme';
import 'normalize.css';
import App from 'containers/App';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // Define a custom insertion for injecting the JSS styles in the DOM
  insertionPoint: document.getElementById('jss-insertion-point')
});

const Root = () => (
  <IntlProvider locale="en">
    <JssProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
      </MuiThemeProvider>
    </JssProvider>
  </IntlProvider>
);

export default Root;
