import React from 'react';
import { Switch, Route } from 'react-router-dom';

// import public screens
import Login from 'screens/LoginScreen';
import Main from 'containers/Main';

const App = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/" component={Main}/>
  </Switch>
);

export default App;
