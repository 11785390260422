import { Network } from 'relay-runtime';
import { parse } from 'graphql';
import { execute, makePromise, from as composeLinks } from 'apollo-link';
import { RetryLink } from 'apollo-link-retry';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { retrieveToken } from 'utils/authenticationUtil';

import { GRAPHQL_SERVER_URL } from 'consts';

const retryLink = new RetryLink();

const httpLink = createHttpLink({
  uri: GRAPHQL_SERVER_URL
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: retrieveToken()
  }
}));

const link = composeLinks([retryLink, authLink, httpLink]);

const fetchQuery = (operation, variables) =>
  makePromise(
    execute(link, {
      query: parse(operation.text),
      variables
    })
  );

const network = Network.create(fetchQuery);

export default network;
