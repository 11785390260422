import styled from 'styled-components';

import media from 'utils/mediaQueries';

const Center = styled.div`
  width: 100%;
  max-width: 1220px;
  box-sizing: border-box;

  margin: 0 auto;

  ${media.mobileWide`
    max-width: 576px;
  `}

  ${media.tablet`
    max-width: 800px;
  `}
`;

export default Center;
