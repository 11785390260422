import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import ProtectedRoute from 'containers/ProtectedRoute';

// import protected screens

const Main = () => (
  <Switch>
    <ProtectedRoute exact path="/" component={() => (<div>protected</div>)} />
    <Redirect to="/" />
  </Switch>
);

export default Main;
