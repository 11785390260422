import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#192942'
    },
    secondary: {
      main: '#d17818'
    },
    error: {
      main: '#EF5B5B'
    },
    text: {
      primary: '#16171b',
      disabled: '#B0B0B0'
    },
    common: {
      black: '#16171b'
    },
    typography: {
      useNextVariants: true,
      fontFamily: ['Helvetica', 'sans-serif'].join(',')
    },
  }
});

export default theme;
