import React from 'react';
import styled from 'styled-components';
import MuiTextFieldBase from '@material-ui/core/TextField';

const MuiTextField = styled(MuiTextFieldBase)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus, 
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  input[type="date"]::-webkit-inner-spin-button { 
    -webkit-appearance: none;
    display: none;
  }
`;

const TextField = ({ field, form, ...rest }) => (
  <MuiTextField
    {...field}
    disabled={form.isSubmitting}
    error={form.touched[field.name] && !!form.errors[field.name]}
    helperText={
      form.touched[field.name] && form.errors[field.name]
    }
    {...rest}
  />
);

export default TextField;
