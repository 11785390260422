import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'common.required',
    defaultMessage: 'Required'
  },
  genericError : {
    id: 'common.genericError',
    defaultMessage: 'Some error occured. Please try again later.'
  }
});

export default messages;
